<template>
  <div class="text-center ma-2">
    <v-snackbar right v-model="$store.state.notify.is">
      <div class="d-flex justify-space-between align-center">
        {{ text }}
        <v-btn
            style="background-color: #4B4BDF"
            text

            @click="$store.state.notify.is = false"
        >
          متوجه شدم
        </v-btn>
      </div>
    </v-snackbar>

  </div>
</template>
<script>

export default {
  data: () => ({}),
  computed: {
    text() {
      return this.$store.state.notify.text;
    },
  },
}
</script>