<template>
<div>

  <v-app-bar
      class="d-md-none"
      color="#1a1927"
      fixed
  >
    <v-container class="wrapper pa-0 d-flex justify-space-between align-center">

      <div>
        <v-app-bar-nav-icon class="white--text" @click="drawer = true" ></v-app-bar-nav-icon>
      </div>

      <!--        <div class="logo">
                <img src="../assets/image/Header/logo.png" class="headerimg"/>
              </div>-->
<!--      <p class="sitetitle ma-0" style="font-size: 30px" >مهران مدیا</p>-->

    </v-container>
  </v-app-bar>


  <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      fixed
      class="py-10 px-4"
      style="background-color: #1a1927"
  >

<!--    <div class="d-flex align-center">
      <div>
        <img src="../assets/images/img_avatar.png" class="avatarimg"/>
      </div>
      <div>
        <p class="ma-0 mt-1 mr-3" style="color: #d1d1d1;font-size: 14px">امید خدارحمی</p>
        <div class="avatarjob mr-1">
          <p class="ma-0" style="font-size: 10px;color: #4eb2de" >کاربر ویژه</p>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <router-link class="ma-0" to="/" custom v-slot="{navigate,isExactActive}" >

        <div @click="navigate" role="link" :class="{'sidebaritemactive' : isExactActive}" class="sidebaritem my-2 d-flex align-center">
          <v-icon style="color: #6c6c6c">mdi-home</v-icon>
          <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">خانه</p>
        </div>

      </router-link>

&lt;!&ndash;      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-music</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">موسیقی</p>
      </div>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-movie-open</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">فیلم</p>
      </div>

      <router-link class="ma-0" to="/sport" tag="p" active-class="sidebaritemactive" exact>
        <div class="sidebaritem my-2 d-flex align-center">
          <v-icon style="color: #6c6c6c">mdi-soccer</v-icon>
          <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">ورزشی</p>
        </div>
      </router-link>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-video</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">مستند</p>
      </div>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-palette</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">گرافیک</p>
      </div>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-transition</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">انیمیشن</p>
      </div>&ndash;&gt;

      <div class="divider mt-6 mb-5"></div>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-newspaper-variant</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">اخبار</p>
      </div>

      <div class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-logout</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">خروج</p>
      </div>

    </div>-->

    <SideBar :sidebackground="false"></SideBar>


  </v-navigation-drawer>
</div>
</template>

<script>
import SideBar from "./SideBar";
export default {
  name: 'App',

  data() {
    return {
      drawer: false,
      group: null,
    }
  },
  components : {
    SideBar
  }
};
</script>
