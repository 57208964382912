import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#4B4BDF',
                secondary: '#b6b6b6',
                accent: '#949494',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',

            },
        },
    },
});
