<template>
  <div >
    <template>
      <v-row justify="center" >
        <v-dialog v-if="dialog"   v-model="$store.state.loginDialog" max-width="290">
          <SignIn class="dialog2"></SignIn>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>
<script>
import SignIn from "../views/SignIn";
import { VRow,VDialog } from 'vuetify/lib'

export default {
  components:{
    VRow,VDialog,
    SignIn
  },
  data () {
    return {
      rate:0,
      text:"",
      order:true,
    }
  },

  methods:{
  },
  computed:{
    loggedIn() {
      return this.$store.state.userId;
    },
    dialog() {
      return !!(!this.loggedIn && this.$store.state.loginDialog);
    },
    loginDialog() {
      return this.$store.state.loginDialog;
    },
  },
  created(){
  },
}
</script>

<style scoped>
.dialog2{
  background: #21202e!important;
  padding: 20px;
}
</style>
