<template>
  <div class="sidebar" :class="{'sidebar__background':sidebackground}">
    <router-link :to="{name:'profile'}" custom v-slot="{ navigate , href }">
    <div @click="navigate" role="link" class="d-flex align-center" style="cursor: pointer">
    <div>
      <div v-if="sidebarloading">
        <a v-if="profile.image" :href="href" role="link">
          <img   :src="$store.state.smallthumbnail + profile.image" class="avatarimg"/>
        </a>

        <a v-else :href="href" role="link">
          <img  src="../assets/images/colorFulllogo-mehranmedia.png" style="border-radius: 0px;width: 58px;margin-right: 10px;margin-top: 15px" class="avatarimg"/>
        </a>

      </div>
      <v-skeleton-loader
          dark
          v-else
          class="mr-1"
          style="width: 54px;border-radius: 50%;height: 54px"
          type="image"
      ></v-skeleton-loader>
    </div>
    <div>
    <a :href="href" role="link" v-if="profile.id" class="ma-0 mt-1 mr-2" style="color: #d1d1d1;font-size: 14px">{{profile.fName}} {{profile.lName}}</a>
      <div v-if="sidebarloading" class="d-flex align-center">
    <div v-if="profile.id" class="avatarjob mr-1" :class="{'borderjob':profile.premiumTime }">
      <p v-if="profile.premiumTime" class="ma-0" style="font-size: 10px;color: #4eb2de" ><a style="font-size: 10px;color: #4eb2de !important;" :href="href" role="link">کاربر ویژه</a></p>
      <p v-else-if="!profile.premiumTime" class="ma-0" style="font-size: 10px;color: #d0980c !important;" ><a style="color: #d0980c !important;" :href="href" role="link">کاربر عادی</a></p>
    </div>
      </div>
      <v-skeleton-loader
          dark
          v-else
          width="150px"
          type="list-item-three-line"
      ></v-skeleton-loader>
    </div>
    </div>
    </router-link>
    <div v-if="$store.state.userId">

    <div v-if="profile.premiumTime && getPerimium(profile.premiumTime)">
    <p  class="ma-0 blue--text mt-1 mr-1 text-center" style="font-size: 12px" ><span class="grey--text">انقضاء :  </span>{{ profile.premiumTime | momentDate}} </p>
    <p  class="ma-0 blue--text mt-1 mr-1 text-center" style="font-size: 12px" >{{ profile.premiumTime | momentRel}} <span class="grey--text"> روز مانده</span></p>
    </div>
    <div v-else>
      <router-link :to="{name:'permiumuser'}" custom v-slot="{navigate,href}" >
      <p class="ma-0 blue--text mt-1 mr-1 text-center" style="font-size: 12px;cursor: pointer;text-decoration: none" >
        <a @click="navigate" :href="href" role="link" >ارتقا به ویژه</a></p>
      </router-link>
    </div>
    </div>

    <div class="mt-12" v-if="sidebarloading">


      <router-link class="ma-0" to="/" custom v-slot="{navigate,isExactActive,href}" >
        <div @click="navigate" role="link" :class="{'sidebaritemactive' : isExactActive}" class="sidebaritem my-2 d-flex align-center">
          <v-icon style="color: #6c6c6c">mdi-home</v-icon>
          <a :href="href" class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c;text-decoration: none">خانه</a>
        </div>
      </router-link>

<!--      ////////////////////cats/////////////////// -->


      <div v-for="(cat,i) in headCats" :key="i">
      <router-link class="ma-0" :to="{name:'posts',params:{id:cat.id,title:cat.title}}" custom v-slot="{navigate,isExactActive,href}" >
        <a @click="navigate" role="link" :class="{'sidebaritemactive' : isExactActive}" class="sidebaritem my-2 d-flex align-center">
          <v-img style="filter: brightness(50%)" :src="$store.state.file+ cat.icon" max-width="22px" ></v-img>
          <a :href="href" class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c;text-decoration: none">{{cat.title}}</a>
          <v-icon class="mr-auto" :class="{'returnicon':address && address.length && address[address.length-1].title == cat.title}" style="color: #6c6c6c" >mdi-chevron-left</v-icon>
        </a>
      </router-link>
        <div class="d-flex flex-wrap mb-1" v-if="address && address.length && address[address.length-1].title == cat.title" >
          <div v-for="(add,i) in address" :key="i + 'add'" style="margin-right: 5px"  >
            <router-link v-if="address" custom v-slot="{navigate,href,isExactActive}" :to="{name:'posts',params:{id:address[address.length-1-i].id,title:address[address.length-1-i].title}}">
              <a @click="navigate" :href="href" :class="{'sidebaritemactive' : isExactActive}"  v-if="i!=0" class="ma-0 d-inline-block catpath" ><span v-if="address">{{address[address.length-1-i].title}}</span> <span style="cursor: default" class="grey--text">/</span> </a>
            </router-link>
          </div>
        </div>

        <div v-if="address && address.length && address[address.length-1].title == cat.title">
        <div v-for="(cat,j) in cats" :key="j+'sc'">

          <router-link class="ma-0" :to="{name:'posts',params:{id:cat.id,title:cat.title}}" custom v-slot="{navigate,isExactActive,href}" >
          <a @click="navigate" role="link" :href="href" :class="{'sidebaritemactive':isExactActive}" class="sidebaritem my-2 mr-5 d-flex align-center">
            <v-img style="filter: brightness(50%);border-radius: 2px" :src="$store.state.file+ cat.icon" max-width="22px" ></v-img>
            <a :href="href" class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c;text-decoration: none" >{{cat.title}}</a>
          </a>
          </router-link>

        </div>
        </div>

      </div>


<!--  //////////////////////////    -->

      <div class="divider mt-6 mb-5"></div>


      <router-link class="ma-0" to="/contactus" custom v-slot="{navigate,isExactActive,href}" >
        <div @click="navigate" role="link" :class="{'sidebaritemactive' : isExactActive}" class="sidebaritem my-2 d-flex align-center">
          <v-icon style="color: #6c6c6c">mdi-phone</v-icon>
          <a :href="href" class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">تماس با ما</a>
        </div>
      </router-link>

      <router-link :to="{name:'signin'}" v-if="!$store.state.userId" custom v-slot="{navigate,isExactActive,href}" >
      <div :class="{'sidebaritemactive' : isExactActive}" class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-login</v-icon>
        <a :href="href" @click="navigate" class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c" >وارد شوید</a>
      </div>
      </router-link>

      <div v-else @click="logout" class="sidebaritem my-2 d-flex align-center">
        <v-icon style="color: #6c6c6c">mdi-logout</v-icon>
        <p class="ma-0 mt-2 mr-1" style="font-size: 14px;color: #6c6c6c">خروج</p>
      </div>

    </div>

    <div v-else>

    <v-skeleton-loader
        dark
        class="mt-8"
        width="150px"
        type="text@10"
    ></v-skeleton-loader>
    <div class="divider mt-6 mb-5"></div>
      <v-skeleton-loader
          dark
          class="mt-8"
          width="150px"
          type="text@2"
      ></v-skeleton-loader>
    </div>




  </div>
</template>

<script>

import axios from "axios";
import moment from "jalali-moment";
import {mapGetters} from 'vuex';

export default {
  filters : {
    momentDate(date){
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
    },
    momentRel(date){
      var a = moment(new Date());
      var b = moment.unix(date);
      return b.diff(a, 'days');
    }
  },
  name: 'App',
  props : {
    sidebackground : {
      default : true,
    },
  },
  data() {
    return {
      id : null,
      address : null,
      cats : [],
      showchildren :false,
      selectedid : null,
      selectedchildid : null,
      sidebarloading : false,
      profile:[],
      headCats : [],
    }
  },
  created() {

    if(this.$route.name == 'posts'){
      this.id = this.$route.params.id;
    }


    if(this.$store.state.userId){
      axios.post(this.$store.state.api + 'getprofile',{
        userId : this.$store.state.userId
      }).then(response=>{
        this.profile = response.data.data;
        this.sidebarloading = true;
      })
    }

    axios.get(this.$store.state.api + 'allcats',{
      params : {
        catId : this.id,
      }
    }).then(response => {
      this.headCats = response.data.data.parent;
      this.cats = response.data.data.subcat;
      this.address = response.data.data.address;
      this.sidebarloading = true;
    })

  },
  computed: {
    ...mapGetters(['getprofileChange'])
  },
  watch : {
    getprofileChange (){
        axios.post(this.$store.state.api + 'getprofile',{
          userId : this.$store.state.userId
        }).then(response=>{
          this.profile = response.data.data;
          this.$store.state.profileChange = false;
        })
    },
    '$route'(){
      if(this.$route.name == 'posts'){
        this.id = this.$route.params.id;
      }

      axios.get(this.$store.state.api + 'allcats',{
        params : {
          catId : this.id,
        }
      }).then(response => {
        this.headCats = response.data.data.parent;


        if(response.data.data.subcat && response.data.data.subcat.length){
          this.cats = response.data.data.subcat;
        }

        if(response.data.data.address){
          this.address = response.data.data.address;
        }

        this.sidebarloading = true;

        if(response.data.data.subcat && response.data.data.subcat.length==0){

          axios.get(this.$store.state.api + 'allcats',{
            params :{
              catId : this.address[0].parentId
            }
          }).then(response => {
            this.cats = response.data.data.subcat;

          })
          this.address.splice(0,1);
        }

      })
    }
  },
  methods : {
    getPerimium(date){
        var a = moment(new Date());
        var b = moment.unix(date);
        if(b.diff(a)>=0){
          return true;
        } else {
          return false;
        }
    },
    logout(){
      this.$store.state.userId = null;
      this.profile=[];
      localStorage.clear();
      this.$store.state.profileChange = true;
      this.$router.push({name:'Home'}).catch(()=>{});
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = 'خارج شدید !';
    }
  }
};
</script>


<style lang="scss" >

.theme--dark.v-skeleton-loader .v-skeleton-loader__actions, .theme--dark.v-skeleton-loader .v-skeleton-loader__article, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__card-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__date-picker, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-text, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-heading, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-thead, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tbody, .theme--dark.v-skeleton-loader .v-skeleton-loader__table-tfoot{
  background: none !important;
}

.catpath{
  font-size: 12px;
  width: 100%;
  color: #939393 !important;
  cursor: pointer;
}

.catpath:hover {
  color: #d9d9d9 !important;
}
</style>
