import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = resolve => {
  require.ensure(['../views/Home'], () => {
    resolve(require('../views/Home'));
  });
};

const ContactUs = resolve => {
  require.ensure(['../views/ContactUs'], () => {
    resolve(require('../views/ContactUs'));
  });
};

const Posts = resolve => {
  require.ensure(['../views/Posts'], () => {
    resolve(require('../views/Posts'));
  });
};

const Single = resolve => {
  require.ensure(['../views/Single'], () => {
    resolve(require('../views/Single'));
  });
};

const Sign = resolve => {
  require.ensure(['../views/Sign'], () => {
    resolve(require('../views/Sign'));
  });
};

const EditProfile = resolve => {
  require.ensure(['../views/EditProfile'], () => {
    resolve(require('../views/EditProfile'));
  });
};

const PermiumUser = resolve => {
  require.ensure(['../views/PermiumUser'], () => {
    resolve(require('../views/PermiumUser'));
  });
};

Vue.use(VueRouter)

import store from '../store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUs
  },
  {
    path: '/posts/:id/:title?',
    name: 'posts',
    component: Posts
  },
  {
    path: '/signin',
    name: 'signin',
    component: Sign,
    beforeEnter(to, from, next) {
      if (!store.state.userId)
        next();
      else next('/profile');
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: EditProfile,
    beforeEnter(to, from, next) {
      if (store.state.userId)
        next();
      else next('/signin');
    }
  },
  {
    path: '/posts/single/:id/:title?',
    name: 'single',
    component: Single
  },
  {
    path: '/permiumuser',
    name: 'permiumuser',
    component: PermiumUser
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  /*scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {selector: to.hash};
    }
    return {x: 0, y: 0};
  },*/
})

export default router
