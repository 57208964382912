<template>
  <v-app>
    <NavigationDrawer class="mb-md-0 mb-8" ></NavigationDrawer>
    <v-main>
      <v-container class="wrapper d-flex pa-0">
        <SideBar class="d-none d-md-block"></SideBar>
        <div class="router-view" >
          <v-slide-y-reverse-transition mode="out-in">
          <router-view />
          </v-slide-y-reverse-transition>
        </div>
      </v-container>
    </v-main>
    <LoginDialog></LoginDialog>
    <Notify></Notify>
  </v-app>
</template>

<script>
import SideBar from "@/components/SideBar";
import NavigationDrawer from "./components/NavigationDrawer";
import Notify from "./components/Notify";
import LoginDialog from "./components/LoginDialog";

export default {
  name: 'App',

  data: () => ({
    //
  }),
  metaInfo: {
    titleTemplate:
        '%s | '+'مهران مدیا',
    title:'مهران مدیا',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'مهران مدیا دانلود خلاصه تمام مسابقات ورزشی و لیگ های معتبر و مجموعه موسیقی فیلم ها با کیفیت 1080 و والپیپر 4k'

    }],
  },
  components : {
    SideBar,
    NavigationDrawer,
    Notify,
    LoginDialog,

  }
};
</script>

<style lang="scss">

@import "assets/css/fonts.css";
@import "assets/css/custom.scss";
@import "assets/css/Normalize.scss";


.v-application{
  background-color: #191927 !important;
  font-family: "Shabnam FD" !important;
}

.v-application a{
  text-decoration: none;
}

.wrapper {
  max-width: 1280px !important;
}

.router-view{
  width: 1036px;
  min-height: 700px;
}

</style>
