import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let host='https://server.mehranmedia.ir/';
let Id=localStorage.getItem('token')?((localStorage.getItem('token')/1234)-1100)/10000:null;
/*let Id = 1;*/

export default new Vuex.Store({
  state: {
    api:host+'api/',
    userId:Id,

    notify:{
      is:false,
      text:'',
      type:'',
    },

    loginDialog : false,

    file:host+'upload/',
    thumbnail:host+'upload/thumbnail/',
    smallthumbnail:host+'upload/smallthumbnail/',

    profileChange : false,


  },
  getters : {
    getprofileChange : state => state.profileChange,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
