<template>
  <div>

    <v-container style="max-width: 600px;min-height: 60vh">
      <img src="../assets/images/profile/signup.png" width="80%" style="margin-right: 10%">
      <div v-if="!status">
        <div class="d-flex flex-column align-center">
          <div class="txtInput">
          <v-text-field dark dense filled outlined rounded :rules="[tellRules.required, tellRules.pattern]"
                        :disabled="loading" v-model="tell" label="شماره موبایل"></v-text-field>
          </div>
          <v-btn :loading="loading" class="white mx-auto d-inline-block"
                 color="primary"
                 rounded
                 style="width: 200px"
                 @click.prevent="sendNumber">ارسال</v-btn>
        </div>
      </div>


      <div v-if="(status==='login')">
        <div class="d-flex flex-column align-center">
          <v-text-field dark dense filled outlined rounded :disabled="loading" v-model="code" label="کد را وارد کنید"
                        ></v-text-field>
          <span style="color: #9898a1">کد به شماره {{tell}} ارسال شد.</span>
          <a style="color: #9898a1;font-weight: bold" @click="status=null">
            تعویض شماره
          </a>


          <br>
          <v-btn :loading="loading" class="white mx-auto d-inline-block"
                 color="primary"
                 rounded
                 style="width: 200px"
                 @click.prevent="login">تایید</v-btn>
        </div>
      </div>
      <div v-if="(status==='signUp')" class="d-flex flex-column align-center">

        <p style="color: #9898a1; font-size: 22px;" >ثبت نام</p>
        <v-text-field dark dense outlined rounded :disabled="loading" v-model="code" label="کد را وارد کنید"
                      ></v-text-field>
        <v-text-field dark dense outlined rounded :disabled="loading" v-model="fName" label="نام"
                      ></v-text-field>
        <v-text-field dark dense outlined rounded :disabled="loading" v-model="lName" label="نام خانوادگی"
                      ></v-text-field>
        <v-text-field dark dense outlined rounded :disabled="loading" v-model="moarefCode" label="کد معرف(اختیاری)"
                      ></v-text-field>
        <span style="color: #9898a1" >کد به شماره {{tell}} ارسال شد.</span>
        <a style="color: #9898a1;font-weight: bold" @click="status=null">
          تعویض شماره
        </a>


        <br>

        <v-btn :loading="loading" class="white mx-auto d-inline-block"
               color="primary"
               rounded
               style="width: 200px" @click.prevent="signup">تایید</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>

import axios from "axios";

export default {
  created() {
    localStorage.clear();
  },
  data() {
    return {
      tellRules: {
        required: value => !!value ||
            'شماره تماس را وارد کنید',
        pattern: value => {
          const pattern = /^(\+98|0)?9\d{9}$/
          return pattern.test(value) || 'شماره تماس نا معتبر است'
        },
      },
      rules: {
        required: value => !!value ||
            'وارد کردن این فیلد الزامی است',

      },
      loading: false,
      code: '',
      fName: "",
      lName: "",
      moarefCode: "",
      loginId: '',
      tell: '',
      profile: null,
      userId: null,
      status: '',
      id: this.$route.params.id,
    }
  },
  computed: {
    numberValidate() {
      return this.tellRules.pattern(this.tell) === true && this.tellRules.required(this.tell) === true;
    },
    loggedIn() {
      return this.$store.state.userId;
    }
  },

  methods: {
    sendNumber: function () {
      if (!this.numberValidate) {
        this.$store.state.notify.is = true;
        this.$store.state.notify.text = 'شماره نا معتبر است';
        return false;
      }
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = 'ارسال شد';
      this.loading = true;
      axios.post(this.$store.state.api + 'tell', {
        tell: this.tell,
        role: 'customer',
        secure: 1
      }).then(response => {
        let res = response.data.data;

        if (response.data.result) {
          if (res.user) {
            this.status = 'login';
            this.profile = res.profile;
          } else {
            this.status = 'signUp';
          }
          this.loginId = res.loginId;
          this.loading = false;

        } else {
          this.loading = false;
          return false;
        }
      });
    },
    signup: function () {
      this.loading = true;
      axios.post(this.$store.state.api + 'checkcode', {id: this.loginId, code: this.code}).then(response => {
        if (response.data.result) {
          this.$store.state.profileChange = true;
          this.signupPost();
          this.loading = false;
        }else {
          this.loading = false;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'کد را اشتباه زدید';
        }
      });
    },
    login: function () {
      this.loading = true;
      axios.post(this.$store.state.api + 'checkcode', {id: this.loginId, code: this.code}).then(response => {
        if (response.data.result) {
          this.$store.state.profileChange = true;
          this.loginToSite();
          this.loading = false;
        }else {
          this.loading = false;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'کد را اشتباه زدید';
        }
      });
    },
    signupPost() {
      axios.post(this.$store.state.api + 'signup', {
        tell: this.tell,
        role: 'customer',
        fName: this.fName,
        lName: this.lName,
        moarefCode: this.moarefCode,
      }).then(response => {
        let res = response.data.data;
        if (response.data.result) {
          this.profile = res.profile;
          this.loading = false;
          this.$store.state.profileChange = true;
          this.loginToSite();
        } else {
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'لطفا مقادیر را درست وارد کنید';

          this.loading = false;
          return false;
        }
      });
    },
    loginToSite: function () {
      localStorage.setItem('token', (this.profile.userId + 1100) * 1234);
      this.$store.state.userId = this.profile.userId;
      this.$store.state.notify.is = true;
      this.$store.state.loginDialog = false;
      this.$store.state.profileChange = true;
      this.$store.state.notify.text = 'خوش آمدید!';
      if (this.$router.currentRoute.name === 'signin') {
        this.$router.push('/');
      }
    }
  }

}
</script>

<style lang="scss" scoped>

/*.addInput .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 24px !important;
}*/

.login-form {
  margin-top: 50px;
}

.btn-kacheb {
  width: 170px !important;

}

.kacheb-input {
  width: 210px !important;
  display: block;
  margin: 10px auto;
}

.main img {
  margin: 0px auto;
}

.loginImage {
  width: 400px;
  max-width: 100% !important;
  margin-bottom: 10px !important;
}

.items {
  width: 150px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 15px;
  min-height: 150px;
}

.titles {
  text-align: right;
}

.img {
  width: 300px !important;
  max-width: 100%;
  border-radius: 10px;
}

.addInput {
  width: 200px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

</style>